<template>
  <div v-if="loaded" id="verify-order-slide">
    <div v-if="showVerifyOrder" class="verify-checkout-intro-container">
      <slide-heading
        :heading="slide.heading"
      />
    </div>

    <div class="row justify-content-center">
      <div class="verify-order-slide-body col-md-12">
        <template v-if="showVerifyOrder">
          <verify-order
            class="verify-order-component"
            :show-title="false"
            @completed="completed()"
          />
        </template>

        <div v-else class="justify-content-center">
          <div class="intro-description w-100">
            <h4>
              Oops! Looks like you changed your mind about hiring us to file for you.
            </h4>
            <h4>
              Let's go back and figure out how you want to file your paperwork.
            </h4>

            <b-button
              variant="primary mt-4"
              class="back-button"
              aria-label="back button"
              @click="goBackToProductSlide"
            >
              Go Back
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ct-centered-spinner v-else>
    Loading...
  </ct-centered-spinner>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'VerifyOrderSlide',
  components: {
    VerifyOrder:         () => import('@/components/VerifyOrder/VerifyOrder'),
    SlideHeading:        () => import('@/components/StagelineV2/shared/SlideHeading'),
    CtCenteredSpinner:   () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
    slide: null,
    readOnly: null,
  },
  data () {
    return {
      loaded: false,
      hasOrderToVerify: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'stageProductSelectedType',
      'company',
    ]),
    ...mapGetters('checkout', [
      'cartEmpty',
      'cartItems',
      'cartItemByCategory',
      'bundleInCartWithFilingProduct',
    ]),
    ...mapGetters('verifyOrder', [
      'orderRequiringVerification',
      'orderRequiringVerificationType',
      'verifyOrderInteraction',
    ]),
    slideTargetProductInCart() {
      return this.cartItemByCategory(this.slide.layout_sub_type) ||
        this.bundleInCartWithFilingProduct(this.slide.layout_sub_type)
    },
    completedVerifyOrder() {
      return !!this.verifyOrderInteraction?.completed_at
    },
    showVerifyOrder () {
      return this.completedVerifyOrder || (this.loaded &&
        this.orderRequiringVerification &&
        (
          this.orderRequiringVerificationType === 'OrderItem' ||
          (this.orderRequiringVerificationType === 'ShoppingBasketItem' && this.slideTargetProductInCart)
        )
      )
    },
  },
  async mounted() {
    this.loaded = false
    if (this.stageProductSelectedType !== 'diy') {
      if (this.slide.hide_name_search) await this.filterOutSection('company_name_lookup')

      await this.loadLatestOrderRequiringVerification({
        companyId: this.company.id,
        filingName: this.slide.layout_sub_type,
      })

      await this.loadCartItems()

      const filingProductInCart = this.slideTargetProductInCart?.product_type === 'ProductBundle' ?
        Object.values(this.slideTargetProductInCart.data).find(value => value.filing_name === this.slide.layout_sub_type) :
        this.slideTargetProductInCart

      const filingMethodId = filingProductInCart?.filing_method_id

      if (this.orderRequiringVerification) {
        this.verifyOrderFound()
      } else if (this.slideTargetProductInCart && filingMethodId) {
        await this.setShoppingCartOrderRequiringVerification(filingProductInCart)
        this.verifyOrderFound()
      } else {
        this.hasOrderToVerify = false
        this.loaded = true
      }
    } else {
      this.hasOrderToVerify = false
      this.loaded = true
    }
  },
  methods: {
    ...mapActions('stageline', [
      'goToSlideInCurrentStageBySlideType',
    ]),
    ...mapActions('verifyOrder', [
      'loadLatestOrderRequiringVerification',
      'setOrderRequiringVerification',
      'filterOutSection',
    ]),
    ...mapActions('checkout', [
      'loadCartItems',
    ]),
    completed() {
      this.$emit('next-slide')
    },
    verifyOrderFound() {
      this.hasOrderToVerify = true
      this.$emit('collapseSidebar')
      this.loaded = true
    },
    async setShoppingCartOrderRequiringVerification(filingProductInCart) {
      const orderRequiringVerification = cloneDeep(filingProductInCart)
      orderRequiringVerification.id = this.slideTargetProductInCart.id
      orderRequiringVerification.type = 'shopping-cart-filing'
      orderRequiringVerification.product = {
        id: filingProductInCart.product_id,
        filing_name: filingProductInCart.display_info?.product_category || filingProductInCart?.filing_name,
        kind: filingProductInCart.kind,
      }

      await this.setOrderRequiringVerification(orderRequiringVerification)

    },
    async goBackToProductSlide() {
      await this.goToSlideInCurrentStageBySlideType('filingOptions')
    },
  },
}
</script>

<style lang="scss">
#verify-order-slide {
  padding: 0 !important;
  text-align: center;

  .verify-order-intro-container {
    padding-bottom: 2.0em;
  }

  .verify-order-component {
    padding: 0px;
  }

  .intro-description,
  .intro-slide-link {
    display: inline-block;
    margin: 0 1rem;
  }
  .verify-order-slide-body {
    .verify-container {
      min-width: 20em;
    }
    .order-container {
      box-shadow: 1px 2px 5px grey;
      border-radius: 4px;
      min-width: 31.25em;
      height: min-content;
      padding: 0;

      .order-cart-body {
        .order-cart-header {
          padding: 1em 1.5em 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .cart-icon {
            padding-right: 0.5em;
          }
        }
        .card-header-text {
          margin-top: 0;
        }

        #select-payment {
          max-height: 44vh;
          overflow: scroll;
          h5.card-header-text {
            margin-top: 0;
          }
        }
      }
    }
  }
  .verify-order-slide-body.hideCart {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 992px) {
  #verify-order-slide {
    .verify-order-slide-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 660px) {
  #verify-order-slide {
    width: 100%;
  }
}
</style>
