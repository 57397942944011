var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { attrs: { id: "verify-order-slide" } }, [
        _vm.showVerifyOrder
          ? _c(
              "div",
              { staticClass: "verify-checkout-intro-container" },
              [_c("slide-heading", { attrs: { heading: _vm.slide.heading } })],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "verify-order-slide-body col-md-12" },
            [
              _vm.showVerifyOrder
                ? [
                    _c("verify-order", {
                      staticClass: "verify-order-component",
                      attrs: { "show-title": false },
                      on: {
                        completed: function ($event) {
                          return _vm.completed()
                        },
                      },
                    }),
                  ]
                : _c("div", { staticClass: "justify-content-center" }, [
                    _c(
                      "div",
                      { staticClass: "intro-description w-100" },
                      [
                        _c("h4", [
                          _vm._v(
                            "\n            Oops! Looks like you changed your mind about hiring us to file for you.\n          "
                          ),
                        ]),
                        _c("h4", [
                          _vm._v(
                            "\n            Let's go back and figure out how you want to file your paperwork.\n          "
                          ),
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "back-button",
                            attrs: {
                              variant: "primary mt-4",
                              "aria-label": "back button",
                            },
                            on: { click: _vm.goBackToProductSlide },
                          },
                          [_vm._v("\n            Go Back\n          ")]
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            2
          ),
        ]),
      ])
    : _c("ct-centered-spinner", [_vm._v("\n  Loading...\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }